<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <v-card class="rounded-xl bg-primary-lighten-1 pt-10 pb-2 pa-5" >
        <v-card-title class="text-center text-wrap">
          <h1 class="break-words">Schedule SMS online</h1>
        </v-card-title>
        <v-card-text class="text-center mt-3">
          Here you can send and receive for as little as €0.02 per message, world-wide.
        </v-card-text>
        <div class="mt-10"/>
        <v-card-item>
          <v-row >
            <v-col cols="12" md="4" offset-md="4">
              <v-btn :to="Routes.pricing" block>
                Pricing
              </v-btn>
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" offset-md="3" v-show="false">
      <client-only>
        <v-banner>
          <v-banner-text>
            Try {{ $t('app.name') }} for free with your first messages.
          </v-banner-text>
          <template v-slot:actions>
            <v-btn>
              Get started for free now
            </v-btn>
          </template>
        </v-banner>
      </client-only>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import {navigateTo, onKeyStroke} from "#imports";
import {Routes} from "~/lib/navigation";

definePageMeta({
  layout: 'pre-launch',
});

if (process.client) {
  onKeyStroke('k', (event) => {
    console.log(event.key)
    if (event.ctrlKey) {
      navigateTo(Routes.login)
    }
  }, {target: document})
}
</script>